import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { AiOutlineLoading } from 'react-icons/ai';
import { Button } from 'flowbite-react';
import { MonthCost, MonthType, YearCost, company, logo, serverURL } from '../constants';
import axios from 'axios';
import ReceiptDisplay from '../components/receiptDisplay';
import { Spinner } from 'flowbite-react';
import { toast } from 'react-toastify';
import ReceiptDisplayRazorpay from '../components/receiptDisplayRazorpay';
import { useLocation } from 'react-router-dom';

const SuccessGpay = () => {

    const [processing, setProcessing] = useState(false);
    const { state } = useLocation();
    const {paymentRequest, mName, lastName, email, address, country} = state || {};
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getDetails();
    }, []);

    async function getDetails() {
        const dataToSend = {
            uid: sessionStorage.getItem('uid'),
            plan: sessionStorage.getItem('plan')
        };
        try {
            const postURL = serverURL + '/api/gpaydetails';
            await axios.post(postURL, dataToSend).then(res => {
                sessionStorage.setItem('type', sessionStorage.getItem('plan'));
                setIsLoading(false);
                sendEmail();
            });
        } catch (error) {
            getDetails()
        }
    }

    async function download() {
        const html = `<!DOCTYPE html>
        <html>
            <head>
                <meta charset="utf-8" />
                <title>A simple, clean, and responsive HTML invoice template</title>
        
                <style>
                    .invoice-box {
                        max-width: 800px;
                        margin: auto;
                        padding: 30px;
                        font-size: 16px;
                        line-height: 24px;
                        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
                        color: #555;
                    }
        
                    .invoice-box table {
                        width: 100%;
                        line-height: inherit;
                        text-align: left;
                    }
        
                    .invoice-box table td {
                        padding: 5px;
                        vertical-align: top;
                    }
        
                    .invoice-box table tr td:nth-child(2) {
                        text-align: right;
                    }
        
                    .invoice-box table tr.top table td {
                        padding-bottom: 20px;
                    }
        
                    .invoice-box table tr.top table td.title {
                        font-size: 45px;
                        line-height: 45px;
                        color: #333;
                    }
        
                    .invoice-box table tr.information table td {
                        padding-bottom: 40px;
                    }
        
                    .invoice-box table tr.heading td {
                        background: #eee;
                        border-bottom: 1px solid #ddd;
                        font-weight: bold;
                    }
        
                    .invoice-box table tr.details td {
                        padding-bottom: 20px;
                    }
        
                    .invoice-box table tr.item td {
                        border-bottom: 1px solid #eee;
                    }
        
                    .invoice-box table tr.item.last td {
                        border-bottom: none;
                    }
        
                    .invoice-box table tr.total td:nth-child(2) {
                        border-top: 2px solid #eee;
                        font-weight: bold;
                    }
        
                    @media only screen and (max-width: 600px) {
                        .invoice-box table tr.top table td {
                            width: 100%;
                            display: block;
                            text-align: center;
                        }
        
                        .invoice-box table tr.information table td {
                            width: 100%;
                            display: block;
                            text-align: center;
                        }
                    }
        
                    /** RTL **/
                    .invoice-box.rtl {
                        direction: rtl;
                        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
                    }
        
                    .invoice-box.rtl table {
                        text-align: right;
                    }
        
                    .invoice-box.rtl table tr td:nth-child(2) {
                        text-align: left;
                    }
                </style>
            </head>
        
            <body>
                <div class="invoice-box">
                    <table cellpadding="0" cellspacing="0">
                        <tr class="top">
                            <td colspan="2">
                                <table>
                                    <tr>
                                        <td class="title">
                                            <img
                                                src=${logo}
                                                style="width: 100%; max-width: 50px"
                                            />
                                        </td>
        
                                        <td>
                                            Subscription Id: ${sessionStorage.getItem('uid')}<br />
                                            Strat Time: ${Date.now()}<br />
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
        
                        <tr class="information">
                            <td colspan="2">
                                <table>
                                    <tr>
                                        <td>
                                        <strong>${company}</strong>
                                            <br />
                                        </td>
        
                                        <td>
                                            ${mName + " " + lastName}<br />
                                            ${address}, ${country}<br />
                                        ${sessionStorage.getItem('email')}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
        
                        <tr class="heading">
                            <td>Payment Method</td>
        
                            <td></td>
                        </tr>
        
                        <tr class="details">
                            <td>${sessionStorage.getItem('method')}</td>
        
                            <td></td>
                        </tr>
        
                        <tr class="heading">
                            <td>Item</td>
        
                            <td>Price</td>
                        </tr>
        
                        <tr class="item">
                            <td>${sessionStorage.getItem('plan')}</td>
        
                            <td>${sessionStorage.getItem('plan') === "Monthly Plan" ? MonthCost : YearCost} USD</td>
                        </tr>
        
                        <tr class="total">
                            <td></td>
        
                            <td>Total: ${sessionStorage.getItem('plan') === "Monthly Plan" ? MonthCost : YearCost} USD</td>
                        </tr>
                    </table>
                </div>
            </body>
        </html>`;
        setProcessing(true);
        const email = sessionStorage.getItem('email');
        const postURL = serverURL + '/api/downloadreceipt';
        const response = await axios.post(postURL, { html, email });
        if (response.data.success) {
            showToast(response.data.message);
        } else {
            download();
        }
    }

    const showToast = async (msg) => {
        setProcessing(false);
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    async function sendEmail() {
        const html = `<!DOCTYPE html>
        <html>
            <head>
                <meta charset="utf-8" />
                <title>A simple, clean, and responsive HTML invoice template</title>
        
                <style>
                    .invoice-box {
                        max-width: 800px;
                        margin: auto;
                        padding: 30px;
                        font-size: 16px;
                        line-height: 24px;
                        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
                        color: #555;
                    }
        
                    .invoice-box table {
                        width: 100%;
                        line-height: inherit;
                        text-align: left;
                    }
        
                    .invoice-box table td {
                        padding: 5px;
                        vertical-align: top;
                    }
        
                    .invoice-box table tr td:nth-child(2) {
                        text-align: right;
                    }
        
                    .invoice-box table tr.top table td {
                        padding-bottom: 20px;
                    }
        
                    .invoice-box table tr.top table td.title {
                        font-size: 45px;
                        line-height: 45px;
                        color: #333;
                    }
        
                    .invoice-box table tr.information table td {
                        padding-bottom: 40px;
                    }
        
                    .invoice-box table tr.heading td {
                        background: #eee;
                        border-bottom: 1px solid #ddd;
                        font-weight: bold;
                    }
        
                    .invoice-box table tr.details td {
                        padding-bottom: 20px;
                    }
        
                    .invoice-box table tr.item td {
                        border-bottom: 1px solid #eee;
                    }
        
                    .invoice-box table tr.item.last td {
                        border-bottom: none;
                    }
        
                    .invoice-box table tr.total td:nth-child(2) {
                        border-top: 2px solid #eee;
                        font-weight: bold;
                    }
        
                    @media only screen and (max-width: 600px) {
                        .invoice-box table tr.top table td {
                            width: 100%;
                            display: block;
                            text-align: center;
                        }
        
                        .invoice-box table tr.information table td {
                            width: 100%;
                            display: block;
                            text-align: center;
                        }
                    }
        
                    /** RTL **/
                    .invoice-box.rtl {
                        direction: rtl;
                        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
                    }
        
                    .invoice-box.rtl table {
                        text-align: right;
                    }
        
                    .invoice-box.rtl table tr td:nth-child(2) {
                        text-align: left;
                    }
                </style>
            </head>
        
            <body>
                <div class="invoice-box">
                    <table cellpadding="0" cellspacing="0">
                        <tr class="top">
                            <td colspan="2">
                                <table>
                                    <tr>
                                        <td class="title">
                                            <img
                                                src=${logo}
                                                style="width: 100%; max-width: 50px"
                                            />
                                        </td>
        
                                        <td>
                                            Subscription Id: ${sessionStorage.getItem('uid')}<br />
                                            Strat Time: ${Date.now()}<br />
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
        
                        <tr class="information">
                            <td colspan="2">
                                <table>
                                    <tr>
                                        <td>
                                        <strong>${company}</strong>
                                            <br />
                                        </td>
        
                                        <td>
                                            ${mName + " " + lastName}<br />
                                            ${address}, ${country}<br />
                                        ${email}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
        
                        <tr class="heading">
                            <td>Payment Method</td>
        
                            <td></td>
                        </tr>
        
                        <tr class="details">
                            <td>${sessionStorage.getItem('method')}</td>
        
                            <td></td>
                        </tr>
        
                        <tr class="heading">
                            <td>Item</td>
        
                            <td>Price</td>
                        </tr>
        
                        <tr class="item">
                            <td>${sessionStorage.getItem('plan')}</td>
        
                            <td>${sessionStorage.getItem('plan') === "Monthly Plan" ? MonthCost : YearCost} USD</td>
                        </tr>
        
                        <tr class="total">
                            <td></td>
        
                            <td>Total: ${sessionStorage.getItem('plan') === "Monthly Plan" ? MonthCost : YearCost} USD</td>
                        </tr>
                    </table>
                </div>
            </body>
        </html>`;
        try {
            const email = sessionStorage.getItem('email');
            const plan = sessionStorage.getItem('plan');
            const user = sessionStorage.getItem('uid');
            const subscription = user;
            const subscriberId = email;
            const method = sessionStorage.getItem('method');
            const postURL = serverURL + '/api/sendreceipt';
            await axios.post(postURL, { html, email, plan, subscriberId, user, method, subscription });
        } catch (error) {
            //ERROR
        }
    }

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={true} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex flex-col items-center justify-center py-8'>
                    <p className='text-center font-black text-4xl text-black dark:text-white'>Thank You🎉</p>
                    <p className='text-center font-normal text-black py-4 dark:text-white'><strong>{sessionStorage.getItem('mName')}</strong> for subscribing to our <strong>{sessionStorage.getItem('plan')}</strong> Plan. <br></br> Download your Receipt</p>
                    <Button onClick={download} isProcessing={processing} processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />} className='items-center justify-center text-center dark:bg-white dark:text-black bg-black text-white font-bold rounded-none max-w-sm enabled:hover:bg-black enabled:focus:bg-black enabled:focus:ring-transparent dark:enabled:hover:bg-white dark:enabled:focus:bg-white dark:enabled:focus:ring-transparent' type="submit">Download</Button>
                    {isLoading && <>
                        <div className="text-center py-10 w-screen flex items-center justify-center">
                            <Spinner size="xl" className='fill-black dark:fill-white' />
                        </div>
                    </>}
                    <div className='mt-4'>
                        <p><strong>Name: </strong>{mName}</p>
                        <p><strong>Email: </strong>{email}</p>
                        <p><strong>Id: </strong>{sessionStorage.getItem('uid')}</p>
                        <p><strong>Address: </strong>{address + " " + country }</p>
                        <p><strong>Plan: </strong>{sessionStorage.getItem('plan')}</p>
                        <p><strong>Amount: </strong>{sessionStorage.getItem('plan') === "Monthly Plan" ? MonthCost : YearCost} USD</p>
                    </div>
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default SuccessGpay;