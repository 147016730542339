//BRAND
export const name = 'ILoveAiCourse';
export const company = 'JK Hub Online';
export const websiteURL = 'https://iloveaicourse.com';
export const serverURL = 'https://iloveaicourse.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/i-love-ai-course.appspot.com/o/logo.png?alt=media&token=88ac0e11-3b48-4356-acc6-e5d8d30c204b';
export const razorpayEnabled = false;
export const paypalEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 299;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 499;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Author";
export const profession = 'User';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/i-love-ai-course.appspot.com/o/logo.png?alt=media&token=88ac0e11-3b48-4356-acc6-e5d8d30c204b';

//PAYPAL
export const paypalPlanIdOne = "P-9L5293711S8641138MYX4FQY";
export const paypalPlanIdTwo = "P-5L507661LU031543SMYX4GNI";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//GOOGLE PAY
export const gPayGateway = "example";
export const gPayGatewayMerchantId = "exampleGatewayMerchantId";
export const gPayMerchantId = "12345678901234567890";

//GOOGLE LOGIN
export const CLIENT = "931389848444-m084j5c7v830o71rqk0hrts57k2cucv5.apps.googleusercontent.com";

//ADSENSE
export const APPROVED = false;
export const SHOWADS = true;