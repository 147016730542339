import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { Button, Spinner } from 'flowbite-react';
import { MonthCost, MonthType, YearCost, YearType, paypalPlanIdOne, paypalPlanIdTwo, serverURL } from '../constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SubscriptionDetails from '../components/subscriptionDetails';
import { AiOutlineLoading } from 'react-icons/ai';
import { toast } from 'react-toastify';
import SubscriptionDetailsRazorpay from '../components/subscriptionDetailsRazorpay';

const Manage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [method, setMethod] = useState('');
    const [plan, setPlan] = useState('');
    const [processing, setProcessing] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (sessionStorage.getItem('type') === 'free') {
            navigate("/pricing");
        } else {
            getDetails();
        }
    }, []);

    async function getDetails() {
        const dataToSend = {
            uid: sessionStorage.getItem('uid')
        };
        try {
            const postURL = serverURL + '/api/subscriptiondetail';
            await axios.post(postURL, dataToSend).then(res => {
                setMethod(res.data.method);
                setPlan(res.data.plan);
                sessionStorage.setItem('method', res.data.method);
                setIsLoading(false);
            });
        } catch (error) {
            //DO NOTHING
        }
    }

    const showToast = async (msg) => {
        setProcessing(false);
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }


    async function cancelSubscription() {
        const dataToSend = {
            id: sessionStorage.getItem('uid')
        };
        try {
            setProcessing(true);
            const postURL = serverURL + '/api/paypalcancel';
            await axios.post(postURL, dataToSend).then(res => {
                showToast("Subscription Cancelled");
                sessionStorage.setItem('type', 'free');
                navigate("/pricing");
            });
        } catch (error) {
            cancelSubscription();
        }
    }

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={true} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex flex-col items-center justify-center py-8'>
                    <p className='text-center font-black text-4xl text-black dark:text-white'>Subscription</p>
                    {isLoading && <>
                        <div className="text-center py-10 w-screen flex items-center justify-center">
                            <Spinner size="xl" className='fill-black dark:fill-white' />
                        </div>
                    </>}
                    <div className='items-center my-4 text-center'>
                        <p>Subscriber Id: {sessionStorage.getItem('uid')}</p>
                        <p>Name: {sessionStorage.getItem('mName')}</p>
                        <p>Email: {sessionStorage.getItem('email')}</p>
                        <p>Payment Method: {method}</p>
                        <p>Plan: {plan}</p>
                        <p>Cost: ${plan === MonthType ? MonthCost : YearCost}</p>
                    </div>
                    <div className='max-w-md'>
                        <Button isProcessing={processing} processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />} className='my-2 items-center justify-center text-center dark:bg-white dark:text-black bg-black text-white font-bold rounded-none w-full enabled:hover:bg-black enabled:focus:bg-black enabled:focus:ring-transparent dark:enabled:hover:bg-white dark:enabled:focus:bg-white dark:enabled:focus:ring-transparent' onClick={cancelSubscription}>Cancel Subscription</Button>
                    </div>
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default Manage;
