import React from 'react';
import { Footer } from 'flowbite-react';
import Logo from '../res/img/logo.svg';
import DarkLogo from '../res/img/darkLogo.svg';
import { APPROVED, company, SHOWADS, websiteURL } from '../constants';
import { useNavigate } from 'react-router-dom';
import { Adsense } from '@ctrl/react-adsense';
import AdsHere from './adsHere';

const Footers = () => {
  const storedTheme = sessionStorage.getItem('darkMode');

  const navigate = useNavigate();
  function redirectAbout() {
    navigate("/about");
  }
  function redirectContact() {
    navigate("/contact");
  }
  function redirectTerms() {
    navigate("/terms");
  }

  function redirectPrivacy() {
    navigate("/privacy");
  }

  return (
    <Footer container className="shadow-none rounded-none dark:bg-black">
      <div className="w-full text-center">
        <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
          <Footer.Brand
            href={websiteURL}
            src={storedTheme === "true" ? DarkLogo : Logo}
          />
          <Footer.LinkGroup>
            <p className="text-black font-bold mx-4 dark:text-white" onClick={redirectAbout}>
              About
            </p>
            <p className="text-black  font-bold  mx-4 dark:text-white" onClick={redirectPrivacy}>
              Privacy Policy
            </p>
            <p className="text-black  font-bold  mx-4 dark:text-white " onClick={redirectTerms}>
              Terms
            </p>
            <p className="text-black  font-bold  mx-4 dark:text-white" onClick={redirectContact}>
              Contact
            </p>
          </Footer.LinkGroup>
        </div>
        <Footer.Divider className="border-black dark:border-white" />
        <Footer.Copyright
          className="text-black dark:text-white font-bold"
          href={websiteURL}
          by={company}
          year={2024}
        />
        {SHOWADS ?
          <div style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100vw',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
          }}>
            {APPROVED ?
              <Adsense
                client="ca-pub-6712278774048853"
                slot="7883681959"
              /> :
              <AdsHere />}
          </div>
          :
          <></>}
      </div>
    </Footer>
  );
};

export default Footers;
