import axios from 'axios';
import { Table } from 'flowbite-react';
import React from 'react';
import { toast } from 'react-toastify';
import { serverURL } from '../../constants';

const UPITable = ({ admin }) => {

    async function removeAdmin(id) {
        const postURL = serverURL + '/api/approveUPI';
        const response = await axios.post(postURL, { id });
        if (response.data.success) {
            showToast(response.data.message);
        }
    }

    const showToast = async (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
        window.location.reload();
    }


    return (
        <div className='flex flex-col py-4'>
            <div className="overflow-x-auto">
                <Table>
                    <Table.Head className='border-b text-black'>
                        <Table.HeadCell className='font-black'>User Id</Table.HeadCell>
                        <Table.HeadCell className='font-black'>Transaction ID</Table.HeadCell>
                        <Table.HeadCell className='font-black'>Plan</Table.HeadCell>
                        <Table.HeadCell className='font-black'>Approve</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {admin.map(user => (
                            <Table.Row key={user._id} className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black">
                                <Table.Cell className="whitespace-normal font-normal text-black dark:text-white">
                                    {user.user}
                                </Table.Cell>
                                <Table.Cell className="whitespace-normal font-normal text-black dark:text-white"> {user.transaction}</Table.Cell>
                                <Table.Cell className="whitespace-normal font-normal text-black dark:text-white"> {user.plan}</Table.Cell>
                                {user.approve === false ?
                                    <Table.Cell onClick={() => removeAdmin(user.user)} className="whitespace-normal font-normal text-blue-800 dark:text-blue-800">Approve</Table.Cell>
                                    :
                                    <Table.Cell className="whitespace-normal font-normal text-black dark:text-white">Approved</Table.Cell>
                                }
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        </div>

    );
};

export default UPITable;
