import { Button, Modal } from 'flowbite-react';
import React, { useState } from 'react';
import { APPROVED } from '../constants';
import { Adsense } from '@ctrl/react-adsense';

const AdsPopUp = () => {
    const [openModal, setOpenModal] = useState(true);

    return (
        <Modal show={openModal} onClose={() => setOpenModal(false)}>
            <Modal.Header></Modal.Header>
            <Modal.Body>
                {APPROVED ?
                    <Adsense
                        client="ca-pub-6712278774048853"
                        slot="3488345218"
                    /> :
                    <p className='bg-gray-300 text-black text-center w-full h-20 content-center'>You Ads will be displayed here</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button color="black" onClick={() => setOpenModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AdsPopUp;