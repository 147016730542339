import Header from '../components/header';
import Footers from '../components/footers';
import UserCourses from '../components/usercourses';
import axios from 'axios';
import React, { useEffect } from 'react';
import {serverURL } from '../constants';

const Home = () => {

    useEffect(() => {
        cancelSubscription();
    }, []);


    async function cancelSubscription() {
        const dataToSend = {
            id: sessionStorage.getItem('uid')
        };
        try {
            const postURL = serverURL + '/api/checksub';
            await axios.post(postURL, dataToSend).then(res => {
                if (res.data.success) {
                    sessionStorage.setItem('type', 'Monthly Plan');
                } else {
                    sessionStorage.setItem('type', 'free');
                }
            });
        } catch (error) {

        }
    }

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={true} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='pb-10'>
                    <UserCourses userId={sessionStorage.getItem('uid')} />
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default Home;
